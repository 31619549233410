import { useState, useEffect } from "react";

const ContainedElement = ({
  aspectRatio = 1,
  containerWidth,
  containerHeight,
  padding = 44,
  children,
}: {
  aspectRatio?: number;
  containerWidth?: number;
  containerHeight?: number;
  padding?: number;
  children?: any;
}) => {
  let [elementWidth, setElementWidth] = useState<number | null>(null);
  let [elementHeight, setElementHeight] = useState<number | null>(null);

  // .8 = tall 1 = square 1.3 = landscape

  useEffect(() => {
    setElementSize();
  }, []);

  useEffect(() => {
    // if the container or the desired aspect ratio changes, redraw element size
    setElementSize();
  }, [aspectRatio, containerHeight, containerWidth, padding]);

  const setElementSize = () => {
    if (!containerHeight || !containerWidth || !aspectRatio) return;

    // set the item to either full width or full height depending on the aspect ratio
    if (aspectRatio <= 1) {
      let setHeight = containerHeight - padding;
      let setWidth = (containerHeight - padding) * aspectRatio;

      // if the item is full height but it's aspect ratio is bigger than the containers this could cause bleed
      if (setWidth > containerWidth) {
        setWidth = containerWidth;
        setHeight = containerWidth * (1 / aspectRatio);
        setWidth = setWidth - padding;
        setHeight = setHeight - padding;
      }

      setElementHeight(setHeight);
      setElementWidth(setWidth);
    } else {
      let setWidth = containerWidth - padding;
      let setHeight = (containerWidth - padding) / aspectRatio;

      setElementHeight(setHeight);
      setElementWidth(setWidth);
    }
  };

  return (
    <>
      {/* If there's no width and height set for the element, don't show it */}
      {!elementWidth || !elementHeight ? null : (
        <div style={{ width: elementWidth, height: elementHeight }}>
          {children}
        </div>
      )}
    </>
  );
};

export default ContainedElement;
