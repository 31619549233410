import { HtmlHTMLAttributes } from "react";

export type levels = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const SectionTitle = ({
  title,
  level = "h1",
}: {
  title: string;
  level?: levels;
}) => {
  return (
    <Heading
      level={level}
      className="text-header-m tracking-tight ls-header dark:text-white font-medium"
    >
      {title}
    </Heading>
  );
};

type HeadingProps = { level?: levels } & HtmlHTMLAttributes<HTMLHeadingElement>;
const Heading = ({ level, ...rest }: HeadingProps) => {
  switch (level) {
    case "h1":
      return <h1 {...rest} />;
    case "h2":
      return <h2 {...rest} />;
    case "h3":
      return <h3 {...rest} />;
    case "h4":
      return <h4 {...rest} />;
    case "h5":
      return <h5 {...rest} />;
    case "h6":
      return <h6 {...rest} />;
    default:
      return <h1 {...rest} />;
  }
};
