import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import AssetCard from "@/client/components/common/AssetCard/AssetCard";
import DropCard from "@/client/components/common/DropCard";
import EasyImage from "@/client/components/common/EasyImage";
import Avatar from "@/client/components/frame-design-system/avatars/Avatar";
import AuctionCard from "@/client/components/frame-design-system/cards/AuctionCard";
import { Eth } from "@/client/components/icons/eth";
import { ShoppingBag } from "@/client/components/icons/shopping-bag";
import { getRelativeTime, numberWithCommas } from "@/client/lib/helpers";
import {
  Platform,
  PlatformData,
  ArtBlocksPlatformKind,
  isArtBlocksPlatform,
} from "@/shared/platforms";
import {
  getArtBlocksPlatformLockup,
  getPlatformColorGradient,
} from "@/shared/artblocks";

export const CardCarousel = ({
  data,
  cardType,
}: {
  data: any;
  cardType: string;
}) => {
  const renderSlideItem = (item: any) => {
    switch (cardType) {
      case "platform":
        return <PlatformCard platform={item} />;
      case "drop":
        return (
          <DropCard
            imageUrl={item?.imageUrl}
            projectName={item?.name}
            artistName={item?.artistName}
            mintStart={item?.mintStartsAt}
            currentInvocations={item?.stats?.supplyTotal}
            maxInvocations={item?.supplyMax}
            aspectRatio={item?.aspectRatio}
            platform={item?.platform}
            collectionIdOrSlug={item?.slug || item?.id}
          />
        );
      case "newFloor":
        return (
          <AssetCard
            contain={true}
            showCollection={true}
            asset={item?.asset}
            assetContract={item?.contractAddress}
            assetTokenId={item?.tokenId}
            collection={item?.assetCollection}
            hideActions={true}
            customContent={
              <div className="p-3">
                <p className="mb-1 text-lg truncate">{item?.asset?.name}</p>
                <div className="flex items-center gap-2">
                  <p className="flex items-center">
                    <Eth
                      className="text-gray-400 dark:text-neutral-400"
                      size={18}
                    />
                    {item?.collectionFloorPriceReset?.newPriceInEth
                      ? Number(
                          item?.collectionFloorPriceReset?.newPriceInEth
                        )?.toFixed(2)
                      : null}
                  </p>
                  <ArrowLeftIcon className="w-5 h-5 -mr-1 text-neutral-300" />
                  <p className="flex items-center text-neutral-400">
                    <Eth
                      className="text-neutral-300 dark:text-neutral-400"
                      size={18}
                    />
                    {item?.collectionFloorPriceReset?.oldPriceInEth
                      ? Number(
                          item?.collectionFloorPriceReset?.oldPriceInEth
                        )?.toFixed(2)
                      : null}
                  </p>
                </div>
                <p className="mt-2 text-sm text-neutral-500">
                  {item?.createdAt
                    ? getRelativeTime(new Date(item?.createdAt))
                    : null}
                </p>
              </div>
            }
          />
        );
      case "sale":
        return (
          <AssetCard
            contain={true}
            showCollection={true}
            asset={item?.asset}
            assetContract={item?.contractAddress}
            assetTokenId={item?.tokenId}
            hideActions={true}
            collection={item?.assetCollection}
            customContent={
              <div className="p-3">
                <p className="mb-1 text-lg truncate">{item?.asset?.name}</p>
                <div className="flex items-center gap-2">
                  <p className="flex items-center">
                    <Eth
                      className="text-gray-400 dark:text-neutral-400"
                      size={18}
                    />
                    {item?.priceInEth
                      ? Number(item?.priceInEth)?.toFixed(2)
                      : null}
                    <span className="ml-1 text-neutral-500">
                      ($
                      {item?.priceInUsd
                        ? numberWithCommas(Number(item?.priceInUsd?.toFixed(2)))
                        : null}
                      )
                    </span>
                  </p>
                </div>
                <p className="mt-2 text-sm text-neutral-500">
                  {item?.createdAt
                    ? getRelativeTime(new Date(item?.createdAt))
                    : null}
                </p>
              </div>
            }
          />
        );
      case "notableCollector":
        return (
          <AssetCard
            contain={true}
            showCollection={true}
            asset={item?.asset}
            assetContract={item?.contractAddress}
            assetTokenId={item?.tokenId}
            collection={item?.assetCollection}
            hideActions={true}
            customContent={
              <div className="p-3">
                <div className="flex items-center justify-between mb-2">
                  <Link
                    href={`/wallets/${
                      item.toAddressWalletInfo
                        ? item?.toAddress
                        : item?.fromAddress
                    }`}
                    className="flex items-center gap-1 truncate transition hover:opacity-70"
                  >
                    <EasyImage
                      size={28}
                      className="rounded-full"
                      imageUrl={`${
                        item.toAddressWalletInfo
                          ? item?.toAddressWalletInfo?.avatarUrl
                          : item?.fromAddressWalletInfo?.avatarUrl
                      }`}
                    />
                    {item.toAddressWalletInfo
                      ? item?.toAddressWalletInfo?.name
                      : item?.fromAddressWalletInfo?.name}
                  </Link>

                  {item.toAddressWalletInfo ? (
                    <div className="flex items-center gap-1 pl-1 pr-2 rounded-md bg-neutral-100 dark:text-neutral-400 dark:bg-neutral-800 text-neutral-600">
                      <ShoppingBag className="w-5 h-5" /> <p>Collected</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1 pl-1 pr-2 rounded-md bg-neutral-100 dark:text-neutral-400 dark:bg-neutral-800 text-neutral-600">
                      <ShoppingBag className="w-5 h-5" /> <p>Sold</p>
                    </div>
                  )}
                </div>
                <p className="mb-1 text-lg truncate">{item?.asset?.name}</p>
                <div className="flex items-center gap-2">
                  <p className="flex items-center">
                    <Eth
                      className="text-gray-400 dark:text-neutral-400"
                      size={18}
                    />
                    {item?.priceInEth
                      ? Number(item?.priceInEth)?.toFixed(2)
                      : null}
                    <span className="ml-1 text-neutral-500">
                      ($
                      {item?.priceInUsd
                        ? numberWithCommas(Number(item?.priceInUsd?.toFixed(2)))
                        : null}
                      )
                    </span>
                  </p>
                </div>
                <p className="mt-2 text-sm text-neutral-500">
                  {item?.createdAt
                    ? getRelativeTime(new Date(item?.createdAt))
                    : null}
                </p>
              </div>
            }
          />
        );
      case "auction":
        let asset = item?.asset;
        return (
          <Link href={`/auctions/${item?.id}`}>
            <AuctionCard
              assetName={asset?.name}
              imageUrl={asset?.imageThumbnailUrl}
              status={item?.status}
              highestBid={item?.highestBidPrice?.amount.native}
              reservePrice={item?.reservePrice?.amount.native}
              endsAt={item?.endsAt}
              aspectRatio={asset?.aspectRatio}
            />
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <Swiper
      // install Swiper modules
      wrapperTag={"div"}
      modules={[Navigation]}
      spaceBetween={50}
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {data?.map((item: any, idx: number) => {
        return <SwiperSlide key={idx}>{renderSlideItem(item)}</SwiperSlide>;
      })}
    </Swiper>
  );
};

const PlatformCard = ({ platform }: { platform: PlatformData }) => {
  const bgColor = platform?.platformColor || "#000000";

  return (
    <Link href={`/category/${platform.slug}`}>
      <div>
        {isArtBlocksPlatform(platform) ? (
          <ArtBlocksPlatformCardBanner platform={platform} />
        ) : (
          <div
            style={{ backgroundColor: bgColor }}
            className="flex items-center justify-center h-60"
          >
            <Avatar
              size="96"
              noBackground={true}
              imageUrl={platform.logoImageUrl}
            />
          </div>
        )}
        <div className="mt-3">
          <div>
            <p className="font-medium text-p-lg line-clamp-1 dark:text-white">
              {platform.name}
            </p>
            <p className="line-clamp-1 text-neutral-600 dark:text-neutral-400 text-p-s">
              {platform.descriptionShort || platform.description}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const abCategoryLockupImageWidthMap = {
  [Platform.ArtBlocksStudio]: "w-[221px]",
  [Platform.ArtBlocksCurated]: "w-[242px]",
  [Platform.ArtBlocksPresents]: "w-[242px]",
  [Platform.ArtBlocksCollaborations]: "w-[351px]",
  [Platform.ArtBlocksFactory]: "w-[242px]",
  [Platform.ArtBlocksPlayground]: "w-[285px]",
  [Platform.ArtBlocksExplorations]: "w-[315px]",
};

const ArtBlocksPlatformCardBanner = ({
  platform,
}: {
  platform: PlatformData & { kind: ArtBlocksPlatformKind };
}) => {
  const backgroundGradient = getPlatformColorGradient(platform.kind);
  const lockupImageUrl = getArtBlocksPlatformLockup(platform.kind);
  const lockupWidth = abCategoryLockupImageWidthMap[platform.kind];

  return (
    <>
      <div
        className="flex justify-center bg-center bg-no-repeat bg-cover h-60"
        style={{
          background: `linear-gradient(90deg, rgba(${backgroundGradient.from}), rgba(${backgroundGradient.to}))`,
        }}
      >
        <div className={`flex justify-center items-center ${lockupWidth}`}>
          <img className="p-12" src={lockupImageUrl} />
        </div>
      </div>
    </>
  );
};
