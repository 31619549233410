import { SectionSubtitle } from "./SectionSubtitle";
import { SectionTitle, levels } from "./SectionTitle";
import SimpleButton from "@/client/components/common/Button";

export const SectionHeader = ({
  title,
  subtitle,
  level = "h1",
  navElementText,
  navElementLink,
}: {
  title: string;
  subtitle?: string;
  level?: levels;
  navElementText?: string;
  navElementLink?: string;
}) => {
  return (
    <div className="space-y-2">
      <SectionTitle title={title} level={level} />
      <div className="flex justify-between items-center">
        {subtitle ? <SectionSubtitle text={subtitle} /> : null}
        {navElementLink && (
          <div className="hidden sm:block">
            <SimpleButton
              as="Link"
              href={navElementLink}
              variant="outline"
              className="px-4 flex-shrink-0 py-3"
            >
              {navElementText || "View all"}
            </SimpleButton>
          </div>
        )}
      </div>
    </div>
  );
};
