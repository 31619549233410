import Countdown from "react-countdown";
import MarketLabel from "../labels/MarketLabel";
import CardAssetContained from "./CardAsset/CardAssetContained";
import { useResizeDetector } from "react-resize-detector";

interface AuctionCard {
  imageUrl: string;
  assetName: string;
  aspectRatio?: number;
  highestBid?: number;
  reservePrice?: number;
  endsAt?: Date | null;
  status?: "OPEN" | "COMPLETED" | "CANCELLED" | undefined;
}

const AuctionCard = ({
  imageUrl,
  assetName,
  aspectRatio = 1,
  status,
  highestBid,
  reservePrice,
  endsAt,
}: AuctionCard) => {
  const { width, height, ref } = useResizeDetector();

  const getPrice = () => {
    if (!highestBid && !reservePrice) return "--";
    if (!highestBid) return reservePrice;
    if (!reservePrice) return highestBid;

    if (highestBid >= reservePrice) {
      return highestBid.toFixed(2);
    } else {
      return reservePrice.toFixed(2);
    }
  };

  const countDownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: any) => {
    // Render a countdown
    return (
      <span>
        {days}D {hours}H {minutes}M {seconds}S
      </span>
    );
  };

  return (
    <div
      ref={ref}
      className="asset-card transition hover:shadow-sm rounded-md overflow-hidden"
    >
      <CardAssetContained imageUrl={imageUrl} />

      <div className="py-3 px-3 bg-neutral-100 dark:text-white dark:bg-neutral-800 text-black border-neutral-200 dark:border-neutral-600 rounded-b-md">
        <div className="flex items-center justify-between">
          <p className="text-p-lg truncate">{assetName}</p>
        </div>
        <div className="flex items-center justify-between mt-1 gap-2">
          <div className="flex items-center gap-2">
            {status === "OPEN" ? (
              <span className="w-4 h-4 flex flex-col items-center justify-center relative">
                <span className="bg-neutral-900 dark:bg-white absolute h-3 w-3 rounded-full block"></span>
                <span className="bg-black dark:bg-white absolute animate-ping h-3 w-3 rounded-full block"></span>
              </span>
            ) : null}

            <p className="text-p-lg font-medium truncate">{getPrice()} ETH</p>
          </div>
          {status === "COMPLETED" ? (
            <p className="text-p-m text-neutral-400">Auction ended</p>
          ) : (
            <>
              {endsAt ? (
                <p className="text-p-m text-neutral-400 truncate">
                  <Countdown date={endsAt} renderer={countDownRenderer} />
                </p>
              ) : (
                <p className="truncate text-p-m text-neutral-400">
                  Not started
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
